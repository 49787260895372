.chat-container {
    display: flex;
    height: 80vh;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .chat-list {
    width: 30%;
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    overflow-y: auto;
  }
  
  .chat-list-item {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chat-list-item:hover {
    background-color: #e8e8e8;
  }
  
  .chat-list-item.selected {
    background-color: #e0e0e0;
  }
  
  .chat-list-item-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .chat-list-item-preview {
    font-size: 0.9em;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .chat-main {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header {
    padding: 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .chat-messages {
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .message {
    max-width: 70%;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
  }
  
  .message-sender {
    align-self: flex-end;
    background-color: #0052FF;
    color: white;
  }
  
  .message-receiver {
    align-self: flex-start;
    background-color: #f0f0f0;
    color: black;
  }
  
  .message-content {
    margin-bottom: 15px;
  }

  .message-timestamp {
    font-size: 0.7em; /* Make the timestamp smaller */
    color: #888; /* Use a subtle color */
    position: absolute;
    bottom: 3px;
    right: 7px;
    opacity: 0.8;
  }
  .message-sender .message-timestamp {
    color: rgba(255, 255, 255, 0.7); /* For better visibility on blue background */
  }
  

  .date-separator {
    text-align: center;
    margin: 20px 0;
    font-size: 0.8em;
    color: #888;
  }

  .unread-indicator {
    width: 10px;
    height: 10px;
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
  }

  .chat-input {
    display: flex;
    padding: 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #e0e0e0;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .chat-input button {
    padding: 10px 20px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chat-input button:hover {
    background-color: #1976D2;
  }