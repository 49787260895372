/* src/styles/Header.css */

/* General Header Styles */
.header {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: none;
  position: relative; /* Changed from fixed to relative to prevent desktop disruption */
  top: 0;
  z-index: 1000;
}

/* Desktop Header Styles */
.desktop-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.desktop-header .header-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border: none;
}

/* Logo Styles */
.logo a, .mobile-logo a {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
}

/* Navigation Styles */
.desktop-header nav ul {
  display: flex;
  gap: 25px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.desktop-header nav ul li {
  position: relative;
}

.desktop-header nav ul li a,
.nav-link {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 0;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  cursor: pointer;
  background: none;
  border: none;
}

/* Active Link Highlighting */
.desktop-header nav ul li a.active,
.dropdown-item.active {
  color: var(--primary-color);
  font-weight: 600;
}

/* Hover Effects with Glow */
.desktop-header nav ul li a:hover,
.nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(0, 82, 255, 0.7);
}

/* Dropdown Menu Styles */
.dropdown-menu {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  background-color: white;
  width: max-content;
  min-width: 160px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1001; /* Ensure dropdown is above other elements */
}

.dropdown-menu-open {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

/* Dropdown Content */
.dropdown-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-menu-content a {
  padding: 10px 15px;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

/* Dropdown Hover Glow Effect */
.dropdown-menu-content a:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(0, 82, 255, 0.7);
}

/* Auth Buttons */
.auth-buttons .sign-in,
.auth-buttons .sign-up {
  margin-left: 10px;
  padding: 6px 14px;
  border: 1px solid var(--primary-color);
  border-radius: 18px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.auth-buttons .sign-in {
  background-color: transparent;
  color: var(--primary-color);
}

.auth-buttons .sign-in:hover {
  background-color: var(--primary-color);
  color: white;
}

.auth-buttons .sign-up {
  background-color: var(--primary-color);
  color: white;
}

.auth-buttons .sign-up:hover {
  background-color: #0045d1;
}

/* Icon Container Styles */
.icon-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.icon-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(0, 82, 255, 0.7);
}

.icon-link span {
  font-size: 10px;
  margin-top: 4px;
}

/* Mobile Header Styles */
.mobile-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Mobile Menu Button Styles */
.mobile-menu-button button {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.mobile-menu-button button:hover {
  transform: scale(1.1);
}

/* Mobile Menu Overlay */
.mobile-menu-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 999;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Mobile Menu Styles */
.mobile-menu {
  width: 85%;
  height: 100%;
  background-color: white;
  padding: 20px;
  animation: slideInLeft 0.3s ease;
  overflow-y: auto;
  border-radius: 0;
}

@keyframes slideInLeft {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

.mobile-menu nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu nav ul li {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.mobile-menu nav ul li:last-child {
  border-bottom: none;
}

.mobile-menu nav ul li a,
.mobile-menu nav ul li .nav-link {
  font-size: 18px;
  padding: 10px 0;
  display: block;
}

.mobile-menu nav ul li a.active,
.dropdown-item.active {
  color: var(--primary-color);
  font-weight: 600;
}

.mobile-menu nav ul li a:hover,
.mobile-menu nav ul li .nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(0, 82, 255, 0.7);
}

/* Auth Buttons for Mobile */
.auth-buttons-mobile {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.mobile-auth-buttons {
  margin-bottom: 25px;
}

.mobile-auth-buttons .icon-container {
  flex-direction: row;
  justify-content: space-around;
}

.mobile-auth-buttons .icon-link span {
  font-size: 12px;
}

.mobile-auth-buttons .mobile-sign-in,
.mobile-auth-buttons .mobile-sign-up {
  padding: 10px 0;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mobile-auth-buttons .mobile-sign-in {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.mobile-auth-buttons .mobile-sign-in:hover {
  background-color: var(--primary-color);
  color: white;
}

.mobile-auth-buttons .mobile-sign-up {
  background-color: var(--primary-color);
  color: white;
}

.mobile-auth-buttons .mobile-sign-up:hover {
  background-color: #0045d1;
}

/* Mobile Dropdown Menu Styles */
.mobile-menu .dropdown-menu {
  position: static;
  transform: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}

.mobile-menu .dropdown-menu-content {
  padding-left: 15px;
}

.mobile-menu .dropdown-menu-content a {
  padding: 8px 0;
  font-size: 14px;
}

/* Responsive Adjustments */
@media (min-width: 769px) {
  .mobile-header {
    display: none;
  }
}

@media (max-width: 768px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: flex;
  }

  .header-content {
    padding: 0;
  }

  .auth-buttons .icon-container {
    gap: 10px;
  }

  body {
    padding-top: 60px;
  }

  .mobile-header .mobile-logo a {
    font-size: 24px;
  }

  .mobile-menu-button button {
    padding: 8px;
  }
}

/* Ensure Full-Width Coverage */
.header,
.desktop-header .header-content,
.mobile-header {
  width: 100%;
}

.desktop-header .header-content,
.mobile-menu {
  max-width: 1200px;
  margin: 0 auto;
}

/* Remove Border/Frame - Ensure No Borders in Header Containers */
.desktop-header .header-content {
  border: none;
}

/* Active Link Styling */
.active > a,
.dropdown-item.active {
  color: var(--primary-color);
  font-weight: 600;
}

/* Button Styles in Dropdown to Remove Default Button Appearance */
.desktop-header nav ul li button.nav-link {
  background: none;
  border: none;
  padding: 8px 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.dropdown-menu a {
  display: block;
}

/* Ensure <a> links have the same display properties */
.desktop-header nav ul li a.nav-link {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

/* Hover Effects with Glow */
.desktop-header nav ul li a:hover,
.desktop-header nav ul li button.nav-link:hover,
.nav-link:hover {
  color: var(--primary-color);
  text-shadow: 0 0 8px rgba(0, 82, 255, 0.7);
}

/* Active Link Highlighting */
.desktop-header nav ul li a.active,
.desktop-header nav ul li button.nav-link.active,
.dropdown-item.active {
  color: var(--primary-color);
  font-weight: 600;
}
