/* src/styles/CarListings.css */


body, input, button, select, .MuiTypography-root, .MuiInputBase-root {
  font-family: "Rubik", "Noto Sans Mongolian", sans-serif !important;
}


.car-listings-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.rubik-font {
  font-family: "Rubik", "Noto Sans Mongolian", sans-serif !important;
}

.rubik-font * {
  font-family: "Rubik", "Noto Sans Mongolian", sans-serif !important;
}

.filters {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 12px;
}

.filter-chip {
  margin: 0 4px 4px 0;
  background-color: #e0e0e0;
}

.save-icon {
  color: #888;
  transition: color 0.3s ease;
}

.save-icon.saved {
  color: #090909;  /* Or any color you prefer for saved items */
}

.car-card {
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: box-shadow 0.5s ease;
}

.car-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.car-card .MuiCardMedia-root {
  height: 200px;
  object-fit: cover;
}

.car-card .MuiCardContent-root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.car-title {
  font-weight: 600;
}

.car-price {
  font-weight: 700;
  margin: 10px 0;
  color: #000000;
}

.car-details {
  margin-bottom: 10px;
}

.dealer-info {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.year-range {
  display: flex;
  justify-content: space-between;
}

.year-range input {
  width: 48%;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .desktop-filters {
    display: none;
  }

  .mobile-filter-button {
    display: block;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    padding: 10px 0;
  }

  .mobile-filter-drawer .MuiDrawer-paper {
    width: 80%;
    max-width: 300px;
  }

  .mobile-filters {
    padding: 20px;
  }

  .car-card {
    margin-bottom: 10px;
  }

  .car-card .MuiCardMedia-root {
    height: 150px;
  }

  .car-title {
    font-size: 1.2rem;
  }

  .car-price {
    font-size: 1.5rem;
  }

  .car-details {
    font-size: 0.9rem;
  }

  .dealer-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .dealer-info span {
    margin-bottom: 5px;
  }
}

/* Desktop-specific styles */
@media (min-width: 769px) {
  .mobile-filter-button {
    display: none;
  }
}