/* src/styles/Home.css */

/* Add box-sizing globally */
*, 
*::before, 
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Rubik', 'Noto Sans Mongolian', sans-serif;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 60vh;
}

.car-search, .car-search h2, .car-search input, .car-search button {
  font-family: 'Rubik', 'Noto Sans Mongolian', sans-serif;
}

.auth-buttons button, .mobile-sign-up, .mobile-sign-in {
  font-family: 'Rubik', 'Noto Sans Mongolian', sans-serif;
}

.car-search {
  width: 40%;
}

.car-search h2 {
  font-size: 3.6rem;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.1;
}

.car-search form {
  display: flex;
  flex-direction: column;
}

.select-wrapper {
  position: relative;
  margin-bottom: 15px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
}

.select-wrapper::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.8rem;
  color: #888;
}

.custom-select:focus {
  outline: none;
  border-color: var(--primary-color);
}

.custom-select option {
  padding: 10px;
}

.car-search input, .car-search button {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
}

.car-search input::placeholder {
  color: #888;
}

.car-search button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.3rem;
  transition: background-color 0.3s ease;
}

.car-search button:hover {
  background-color: #0045d1;
}

.signup-section {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sell-car-button .sell-car-btn {
  font-family: 'Rubik', 'Noto Sans Mongolian', sans-serif;
  font-weight: 500;  /* Adjust as needed */
  font-size: 24px;  /* Adjust as needed */
}

.signup-section h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.signup-section p {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 100%;
}

.sell-car-button {
  margin-top: 20px;
}

.sell-car-button button {
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 24px;
  transition: background-color 0.3s ease;
}

.sell-car-button button:hover {
  background-color: #0045d1;
}

/* Car Brands Section - Desktop Styles */
.car-brands-section {
  background-color: #f8f9fa;
  padding: 80px 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-top: 100px;
  overflow: hidden;
}

.car-brands-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.car-brands-text {
  width: 30%;
}

.car-brands-text h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.car-brands-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.see-more-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 24px;
  transition: background-color 0.3s ease;
}

.see-more-button:hover {
  background-color: #0045d1;
}

.car-brands-grid {
  width: 65%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.car-brand-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 250px;
}

.car-brand-card:hover {
  transform: translateY(-5px);
}

.logo-container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 15px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.car-brand-card:hover .brand-logo {
  opacity: 1;
}

.brand-info {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.car-brand-card h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 5px 0;
  color: #333;
}

.car-brand-card p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

/* Mobile optimization */
@media (max-width: 768px) {
  /* Scope mobile styles with .home prefix */
  .home .top-section {
    flex-direction: column;
    padding: 20px;
    min-height: auto;
  }

  .home .car-search, 
  .home .signup-section {
    width: 100%;
  }

  .home .car-search h2, 
  .home .signup-section h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .home .signup-section p {
    text-align: center;
    max-width: 100%;
  }

  .home .car-search button, 
  .home .sell-car-button button {
    font-size: 1.2rem;
    padding: 15px 20px;
    width: 100%;
  }

  .home .year-range {
    flex-direction: column;
  }

  .home .year-range input {
    width: 100%;
    margin-bottom: 10px;
  }

  .home .car-brands-section {
    padding: 40px 0;
    margin-top: 50px;
    width: 100%; /* Adjust width for mobile */
    margin-left: 0;
    overflow: hidden;
  }

  .home .car-brands-content {
    flex-direction: column;
    padding: 0 20px;
    align-items: center;
  }

  .home .car-brands-text {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  .home .car-brands-text h2 {
    font-size: 32px;
  }

  .home .car-brands-text p {
    font-size: 12px; /* Adjust if needed */
  }

  .home .car-brands-grid {
    width: 100%;
    max-width: 500px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    gap: 15px;
    margin: 0 auto;
  }

  .home .car-brand-card {
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: auto; /* Allow height to adjust based on content */
  }

  .home .brand-logo {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }

  .home .see-more-button {
    font-size: 1.2rem;
    padding: 12px 24px;
    display: block;
    text-align: center;
    margin: 0 auto; /* Center the button */
  }
}

/* Additional breakpoint for phones */
@media (max-width: 480px) {
  .home .car-brands-grid {
    grid-template-columns: repeat(2, 1fr); /* Keep 2 columns */
    gap: 10px;
    padding: 0 10px;
  }

  .home .car-brand-card {
    padding: 10px;
  }

  .home .brand-logo {
    width: 50px;
    height: auto;
  }

  .home .car-brand-card h3 {
    font-size: 16px;
  }

  .home .car-brand-card p {
    font-size: 12px;
  }
}
