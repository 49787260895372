/* Car Detail page specific styles */
.car-detail-page .MuiButton-containedPrimary {
  background-color: #000000 !important;
  color: #FFFFFF !important;
}

.car-detail-page .MuiButton-containedPrimary:hover {
  background-color: #333333 !important;
}

.car-detail-page .MuiButton-outlinedPrimary {
  color: #000000 !important;
  border-color: #000000 !important;
}

.car-detail-page .MuiButton-outlinedPrimary:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.car-detail-page .MuiTypography-colorPrimary {
  color: #000000 !important;
}

.car-detail-page .MuiCheckbox-colorPrimary.Mui-checked {
  color: #000000 !important;
}

.car-detail-page .MuiRadio-colorPrimary.Mui-checked {
  color: #000000 !important;
}

.car-detail-page .MuiSwitch-colorPrimary.Mui-checked {
  color: #000000 !important;
}

.car-detail-page .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #000000 !important;
}

.car-detail-page .MuiLink-root {
  color: #000000;
}

.car-detail-page .MuiLink-root:hover {
  color: #333333;
}

.car-detail-page .basics-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.car-detail-page .description-box {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.car-detail-page .description-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}