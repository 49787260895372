/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap" rel="stylesheet');

:root {
  --primary-color: #0052FF;
  --text-color: #050F19;
  --background-color: #F1EFE5;
}

* {
  font-family: 'Rubik', 'Noto Sans Mongolian', sans-serif;
}

body {
  font-family: 'Rubik', 'Noto Sans Mongolian', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #F1EFE5;
  color: var(--text-color);
}

body, input, button, textarea, select {
  font-family: "Rubik", "Noto Sans Mongolian", sans-serif !important;
}


.sell-my-car {
  max-width: 600px;
  margin: 0 auto;
  padding: 80px 20px;
}

.sell-my-car form {
  display: flex;
  flex-direction: column;
}

.sell-my-car .MuiFormControl-root {
  margin-bottom: 15px;
}

.sell-my-car .MuiInputBase-root {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
}

.sell-my-car .MuiInputLabel-root {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
}

.sell-my-car .MuiSelect-select,
.sell-my-car .MuiOutlinedInput-input {
  padding: 15px;
}

.sell-my-car .MuiOutlinedInput-root {
  border-radius: 12px;
}

.sell-my-car .MuiOutlinedInput-notchedOutline {
  border-color: #ccc;
}

.sell-my-car .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color);
}

.sell-my-car .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color);
}

.sell-my-car .image-upload-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sell-my-car .upload-button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sell-my-car .upload-button:hover {
  background-color: #0045d1;
}

.sell-my-car button[type="submit"] {
  padding: 15px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
  transition: background-color 0.3s ease;
}

.sell-my-car button[type="submit"]:hover {
  background-color: #0045d1;
}

.car-link {
  text-decoration: none;
  color: black;
}

.car-listings-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.car-listings h1 {
  text-align: center;
  margin-bottom: 20px;
}

.car-listing-item {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.car-listing-item h2 {
  margin-top: 0;
  color: var(--primary-color);
}

.car-listing-item p {
  margin: 5px 0;
}

.car-listings-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.filters {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
}

.filter-chip {
  margin: 0 4px 4px 0;
  background-color: #e0e0e0;
}


.filters h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.clear-button {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.filter-section {
  margin-top: 20px;
}

.filter-section h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.filter-section select {
  width: 100%;
  padding: 5px;
}

.listings {
  flex-grow: 1;
}

.listings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.save-search {
  background-color: transparent;
  border: 1px solid #0000FF;
  color: #0000FF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.car-listing-item {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
}

.car-card {
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: box-shadow 0.5s ease;
}

.car-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.car-card .MuiCardMedia-root {
  height: 200px;
  object-fit: cover;
}

.car-card .MuiCardContent-root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.car-title {
  font-weight: 600;
}

.car-price {
  font-weight: 700;
  margin: 10px 0;
  color: #000000;
}

.car-details {
  margin-bottom: 10px;
}


.car-image {
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.car-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-image {
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
}

.car-details {
  padding: 20px;
  flex-grow: 1;
}

.car-details h2 {
  font-size: 24px;
  margin: 0 0 5px 0;
}

.car-details h3 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.car-details p {
  margin: 5px 0;
  font-size: 14px;
}


.dealer-info {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}


.view-details-btn {
  margin-top: 10px;
}

.dealer-info span {
  display: block;
}

.MuiTypography-root, .MuiButton-root, .MuiInputBase-root {
  font-family: 'Poppins', sans-serif !important;
}

.MuiFormControlLabel-root {
  display: block;
  margin-bottom: 8px;
}

.MuiCheckbox-root {
  padding: 4px;
}

.MuiSelect-select, .MuiOutlinedInput-input {
  padding: 10px 14px;
}

.MuiButton-outlined {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.MuiButton-outlined:hover {
  background-color: rgba(0, 82, 255, 0.04); /* Using primary color with low opacity */
}

.MuiSelect-icon {
  color: var(--primary-color);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color);
}

.MuiFormLabel-root.Mui-focused {
  color: var(--primary-color);
}

/* Additional styles to ensure consistency */
.MuiButton-containedPrimary {
  background-color: var(--primary-color) !important;
  color: var(--background-color) !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #0045d1 !important; /* Slightly darker shade for hover */
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary-color) !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: var(--primary-color) !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--primary-color) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: var(--primary-color) !important;
}

.save-listing {
  align-self: flex-start;
  margin: 20px;
}

/* .car-brands-section {
  background-color: #f8f9fa;
  padding: 80px 0; 
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-top: 100px;
}

.car-brands-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.car-brands-text {
  width: 30%;
}

.car-brands-text h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.car-brands-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.see-more-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 24px;
  transition: background-color 0.3s ease;
}

.see-more-button:hover {
  background-color: #0045d1;
}

.car-brands-grid {
  width: 65%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.car-brand-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 250px;
}

.car-brand-card:hover {
  transform: translateY(-5px);
}

.car-brand-card .brand-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 15px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.car-brand-card:hover .brand-logo {
  opacity: 1;
}

.car-brand-card h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.car-brand-card p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.car-brand-card .logo-container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.car-brand-card .brand-info {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.icon-container {
  display: flex;
  align-items: center;
}

.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  text-decoration: none;
  color: inherit;
}

.icon-link span {
  font-size: 12px;
  margin-top: 4px;
}


.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.info-item {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.3rem;
}

.info-label {
  font-weight: bold;
  margin-right: 15px;
  /* display: inline-block; */
  width: 150px;
  flex: 1;
}

.info-value {
  flex: 2;
}

.verified-badge {
  background-color: #e0e0e0;
  color: #333;
  padding: 4px 8px; /* Increased padding */
  border-radius: 4px;
  font-size: 1rem; /* Increased font size */
  margin-left: 15px; /* Increased margin */
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 20px;
}

.profile-button {
  padding: 12px 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.edit-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.logout-button, .list-car-button {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.logout-button:hover, .list-car-button:hover {
  background-color: #0045d1;
}

.your-listings-section {
  background-color: #f8f9fa;
  margin-top: 80px;
  padding: 80px 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.your-listings-section .content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

.your-listings-section .section-title {
  margin-bottom: 20px;
}

.your-listings-section .list-car-button {
  margin-top: 20px;
}

.list-car-link {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
}

.your-listings-section h2 {
  font-size: 2.6rem; /* Increased from default h4 size */
  margin-bottom: 40px; /* Increased margin */
}

.profile-page {
  padding-top: 40px;
}

.profile-title {
  font-size: 2.6em; /* This makes the title about 30% larger */
  margin-bottom: 30px;
  padding-left: 20px; /* Align with the logo */
}

.info-item {
  margin-bottom: 20px; /* Increased margin */
  font-size: 1.3rem; /* Increased font size */
}

.button-container {
  padding-left: 20px; /* Align with the logo */
}

.content-container {
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.6rem;
  margin-bottom: 30px;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .header, .content-container {
    padding-left: calc((100% - 1152px) / 2);
    padding-right: calc((100% - 1152px) / 2);
  }
}


@media (max-width: 768px) {
  .info-label {
    width: 120px;
  }
}

@media (max-width: 480px) {
  .info-item {
    flex-direction: column;
  }
  
  .button-container {
    flex-direction: column;
  }
  
  .profile-button {
    width: 100%;
  }
}

.year-range {
  display: flex;
  justify-content: space-between;
}

.year-range input {
  width: 48%; /* Adjust as needed */
}


.get-started-section {
  background-color: var(--primary-color);
  color: white;
  padding: 80px 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.get-started-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.get-started-text {
  width: 50%;
}

.get-started-text h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.get-started-text p {
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 80%;  /* Adjust this value as needed */
  line-height: 1.5;  /* Improves readability */
}

.create-account-btn {
  background-color: white;
  color: var(--primary-color);
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-account-btn:hover {
  background-color: #f0f0f0;
}

.stats {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.stat-item {
  text-align: center;
}

.stat-item h3 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 5px;
}

.stat-item p {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .get-started-content {
    flex-direction: column;
  }

  .get-started-text, .stats {
    width: 100%;
  }

  .stats {
    margin-top: 40px;
  }
}


.trusted-marketplace-section {
  padding: 80px 0;
  background-color: #f5f5f5;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  overflow: hidden;
}

.trusted-marketplace-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.trusted-marketplace-header {
  text-align: center;
  margin-bottom: 60px;
}

.trusted-marketplace-header h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.trusted-marketplace-header p {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.feature-card {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.largest-marketplace {
  background-image: require('../assets/');
}

.protected-transactions {
  background-image: require('../src/assets/home/safe-2.jpeg');
}

.customer-support {
  background-image: require('path_to_your_icon/customer_support.png');
}

.best-practices {
  background-image: require('path_to_your_icon/best_practices.png');
}

.feature-card h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 14px;
  color: #666;
}

@media (max-width: 1024px) {
  .feature-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .feature-cards {
    grid-template-columns: 1fr;
  }
}
.animated-section {
  background: black;
  padding: 50px 0;
}

.container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 10px;
  place-items: center;
  width: 600px;
  margin: 0 auto;
}

.container > svg {
  height: 40px;
  border-radius: 20px;
  filter: grayscale(1) brightness(0.9);
}
.lines-section {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background: black; /* Adjust to match your design */
  padding: 150px 0; /* Adjust padding as needed */
  overflow: hidden;
  display: flex;
  align-items: center;
}

.lines-section svg {
  display: block;
  margin: 0 auto;
}

/* Mobile Header Styles */

.mobile-header {
  display: none;
}

@media (max-width: 768px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
  }

  .mobile-logo {
    display: flex;
    align-items: center;
  }

  .mobile-logo a {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary-color);
    text-decoration: none;
  }

  .mobile-buttons {
    display: flex;
    align-items: center;
  }

  .mobile-header .mobile-sign-up {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
  }

  .mobile-header .mobile-user-icon {
    color: var(--primary-color);
    margin-right: 10px;
  }

  .mobile-header .mobile-menu-button {
    background: none;
    border: none;
    font-size: 28px;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .mobile-menu {
    display: block;
    position: fixed;
    top: 60px; /* Adjusted for fixed header */
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 999;
    overflow-y: auto;
  }

  .mobile-menu-item {
    display: block;
    padding: 15px 20px;
    border-bottom: 1px solid #e5e5e5;
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .mobile-auth-buttons {
    padding: 20px;
  }

  .mobile-sign-up,
  .mobile-sign-in {
    display: block;
    width: 100%;
    padding: 12px 16px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .mobile-sign-up {
    background-color: var(--primary-color);
    color: white;
  }

  .mobile-sign-in {
    background-color: #f0f0f0;
    color: #000;
  }

  /* Adjust main content to account for fixed header */
  .App {
    padding-top: 60px;
  }
}

/* Footer Styles */
.footer {
  background-color: var(--primary-color); /* Blue background */
  color: white; /* White text */
  padding: 20px 0; /* Similar vertical padding as Амархан Шийдэл */
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-info p {
  margin: 0;
  font-size: 1.2rem;
}

.contact-info a {
  color: white;
  text-decoration: underline;
}

.contact-info a:hover {
  color: #e0e0e0;
}

.terms-button {
  background: none;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.terms-button:hover {
  background-color: white;
  color: var(--primary-color);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal {
  background-color: white;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  color: black;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.modal-content {
  font-size: 1rem;
  line-height: 1.6;
}

.terms-text {
  white-space: pre-wrap;
  font-family: 'Rubik', 'Noto Sans Mongolian', sans-serif;
}
